<template>
  <v-dialog :value="value" width="700">
    <v-card color="#fafafa">
      <v-toolbar dark flat color="#1C3144">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Additional TPR Dates</v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0">
        <section class="pa-4" style="border:1px solid #ccc;">
          <v-row dense>
            <v-col>
              <v-text-field tabindex="1" ref="beginDate" v-model="dates.TPRBeginDate" @change="formatDateInput('TPRBeginDate', dates.TPRBeginDate)"
                background-color="#fff" clearable label="TPR Begin Date"
                prepend-inner-icon="mdi-calendar" autocomplete="off" dense outlined>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field tabindex="2" v-model="dates.TPREndDate" @change="formatDateInput('TPREndDate', dates.TPREndDate)" background-color="#fff" label="TPR End Date"
                autocomplete="off" dense outlined prepend-inner-icon="mdi-calendar" clearable>
              </v-text-field>
            </v-col>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn tabindex="3" icon :disabled="disabled" large v-on="on" color="#00B24A" @click="addDate">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add</span>
              </v-tooltip>
          </v-row>
        </section>
        <v-data-table :height="tableSize" :items="contract.TPRDates" :headers="headers" :items-per-page="pageSize"
          fixed-header hide-default-footer class="table" dense no-data-text="No Dates Added">

          <template v-slot:[`item.TPRBeginDate`]="{ item }">
            <span>{{formatDate(item.TPRBeginDate)}}</span>
          </template>

          <template v-slot:[`item.TPREndDate`]="{ item }">
            <span>{{formatDate(item.TPREndDate)}}</span>
          </template>

          <template v-slot:[`item.remove`]="{ item }">
            <v-btn icon @click="selectedItem=item, confirmDialog=true">
              <v-icon color="#D32F2F">mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-container>
    </v-card>

    <ConfirmDialog
      :remove="remove"
      :action="'Delete'"
      :selectedItem="selectedItem"
      :type="'Additional Date'"
      :value.sync="confirmDialog"
    />

  </v-dialog>
</template>

<script>
import { notification } from '@/mixins/notification'

export default {
  name: 'AdditonalTprDates',

  props: ['value', 'contract', 'saveContract'],

  mixins: [notification],

  components: {
    ConfirmDialog: () => import('@/components/shared/confirm-dialog')
  },

  data () {
    return {
      confirmDialog: false,
      selectedItem: {},
      pageSize: 10,
      tableSize: 250,
      dates: {},
      tprBegin: '',
      tprEnd: ''
    }
  },

  computed: {
    headers () {
      return [
        {},
        { text: 'TPR Begin', sortable: true, filterable: true, value: 'TPRBeginDate', class: 'black--text' },
        { text: 'TPR End', sortable: true, filterable: true, value: 'TPREndDate', class: 'black--text' },
        { text: 'Remove', sortable: false, filterable: false, value: 'remove', class: 'black--text' }
      ]
    },

    disabled () {
      if (this.dates.TPRBeginDate && this.dates.TPREndDate) {
        return false
      } else {
        return true
      }
    }
  },

  methods: {
    formatDate (date) {
      return date ? this.moment(date).format(this.$config.date_display) : ''
    },

    formatBeginDate (date) {
      if (!date) return null
      this.dates.tprBegin = this.moment(date, 'M/D/YYYY').format(this.$config.date_display)
    },

    formatEndDate (date) {
      if (!date) return null
      this.dates.tprEnd = this.moment(date, 'M/D/YYYY').format(this.$config.date_display)
    },

    formatDateInput (key, value) {
      this.dates[key] = this.moment(value, ['M/D', 'M/D/YY', 'M/D/YYYY']).format(this.$config.date_display)
      this.$forceUpdate()
    },

    async addDate () {
      this.dates.TPRBeginDate = this.moment(this.dates.TPRBeginDate).format(this.$config.date_format)
      this.dates.TPREndDate = this.moment(this.dates.TPREndDate).format(this.$config.date_format)

      this.contract.TPRDates.push(this.dates)
      await this.saveContract()

      this.dates = {}
    },

    remove (date) {
      var itemIndex = this.contract.TPRDates.indexOf(date, 0)
      this.contract.TPRDates.splice(itemIndex, 1)
      this.notify('success', 'TPR Dates Successfully Deleted')
      setTimeout(() => {
        this.saveContract()
      }, 2000)
    }
  }
}
</script>
